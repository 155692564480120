export default [
    {
        name: "Автомобили до",
        name2: "1 000 000 ₽",
        img: "/media/icons/auto-old/1.svg",
        link: 'https://alarm-motors.ru/avtomobili-s-probegom?price_max=1000000&order_field=price&order_direction=desc&utm_medium=parking_bot&utm_source=telegram'
    },
    {
        name: "Автомобили от ",
        name2: "1 000 000 ₽",
        img: "/media/icons/auto-old/3.svg",
        link: 'https://alarm-motors.ru/avtomobili-s-probegom?price_min=1000000&order_field=price&order_direction=asc&utm_medium=parking_bot&utm_source=telegram'
    },
    {
        name: "Автомобили с пробегом до",
        name2: "50 000 км",
        img: "/media/icons/auto-old/2.svg",
        link: 'https://alarm-motors.ru/avtomobili-s-probegom?carKm=0-50000&utm_medium=parking_bot&utm_source=telegram'
    },
    {
        name: "Автомобили с пробегом от",
        name2: "50 000 км",
        img: "/media/icons/auto-old/4.svg",
        link: 'https://alarm-motors.ru/avtomobili-s-probegom?carKm=50000-350000&utm_medium=parking_bot&utm_source=telegram'
    },
]