<template>
    <section class="services">
        <v-container>
            <h2>Услуги Аларм-Моторс</h2>
            <div class="services__list text-center mb-8 mb-md-16">
                <v-row class="justify-center">
                    <v-col cols="6" md="3" v-for="(item, index) of Services"
                           :key="index">
                        <a
                            :href="item.link"
                            class="services__item ">
                            <div class="services__content">
                                <img :src="item.img" class="services__img"/>
                            </div>
                            <div class="services__text" v-html="item.name"></div>
                        </a>
                    </v-col>
                </v-row>

            </div>

            <popup>
                <button class="btn" type="button">Получить персональное предложение</button>
            </popup>

        </v-container>
    </section>
</template>

<script>
import Services from "../js/services";
import Popup from "@/components/popup";

export default {
    name: 'services',
    components: {Popup},
    data() {
        return {
            Services
        }
    },
}
</script>

<style lang="scss">
.services {
    &__list {

    }

    &__item {
        &:hover {
            color: $colorRed !important;

            & .services__content {
                //border: 2px solid $colorRed;
            }
        }

    }



    &__text {
        margin-top: 15px;
    }

    &__img {
        height: 120px;
        width: 120px;
        display: flex;
        margin: 0 auto;
    }
}
</style>