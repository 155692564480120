<template>
  <section class="map-dealers">
    <div class="map-dealers__desctop d-none d-md-block">
      <BigMap
        :offsetX="offsetX"
        :offsetY="offsetY"
        :markers="dealers"
        :zoom="zoom"
        @marker-click="showDealerDetail"
        @mouseover="onPlaymarkerMouseOver"
        @mouseleave="onPlaymarkerMouseLeave"
        ref="bigMap"
      />
      <div class="map-dealers__dealers-pc-wrap">
        <v-container>
          <DealerCenters
            class="map-dealers__dealers-pc"
            :dealers="dealers"
            ref="dealers"
            @show-all="showAllPlaceMarkers"
            @zoom-map="zoomMap"
          />
        </v-container>
      </div>
    </div>
    <div class="map-dealers__mobile pb-6 d-md-none" >
      <DealerCentersMobile :items="dealers" />
    </div>
  </section>
</template>
<script>
  import Centers from "../../js/centers"

  import BigMap from "./BigMap.vue";
  import DealerCenters from "./DealerCenters.vue";
  import DealerCentersMobile from "./DealerCentersMobile.vue";

  export default {
    name: "HomeMap",

    components: {
      BigMap,
      DealerCenters,
      DealerCentersMobile,
    },

    data: () => ({
      offsetX: 0,
      offsetY: 0,
      zoom: 11,
    }),

    watch: {
      "$vuetify.breakpoint.mdAndDown": {
        handler(val) {
          if (val) {
            this.offsetX = 0;
            this.offsetY = 200;
            this.zoom = 10;
          } else {
            this.offsetX = -236;
            this.offsetY = 0;
            this.zoom = 11;
          }
          if (this.$refs.bigMap) {
            this.showAllPlaceMarkers();
          }
        },
        immediate: true,
      },
    },

    computed: {
      dealers() {
        return Centers;
      },
      isMobile() {
        return this.$vuetify.breakpoint.smAndDown;
      },
    },

    methods: {
      showDealerDetail(item) {
        this.$refs.dealers.currentDealer = item;
      },
      showAllPlaceMarkers() {
        this.$refs.bigMap.setBounds();
      },
      zoomMap(item) {
        this.$refs.bigMap.onClick(item);
      },
      onPlaymarkerMouseOver(item) {
        this.$refs.dealers.hoverItem = item;
      },
      onPlaymarkerMouseLeave() {
        this.$refs.dealers.hoverItem = null;
      },
    },

    mounted() {},
  };
</script>

<style lang="scss">
  .map-dealers {
      &__desctop {
          position: relative;
      }

      &__dealers-pc {
          pointer-events: all;

          &-wrap {
              position: absolute;
              top: 0;
              width: 100%;

              pointer-events: none;

          }

          &__mobile {
              padding: 0 10px;
          }
      }
  }
</style>
