export default [
    {
        name: "<b>Выкуп вашего автомобиля:</b> дорого и за час",
        img: "/media/icons/services/4.svg",
        link: 'https://alarm-motors.ru/repurchase?utm_medium=parking_bot&utm_source=telegram'
    },
    {
        name: "<b>Страхование:</b> ОСАГО, КАСКО, по франшизе, GAP, тотал, угон",
        img: "/media/icons/services/3.svg",
        link: 'https://alarm-motors.ru/strahovanie-osago?utm_medium=parking_bot&utm_source=telegram'
    },
    {
        name: "<b>МКЦ:</b> согласуем ремонт у страховой компании за вас ",
        img: "/media/icons/services/2.svg",
        link: 'https://alarm-motors.ru/servis/udalennoe-uregulirovanie-ubytkov?utm_medium=parking_bot&utm_source=telegram'
    },
    {
        name: "Обменяем ваш авто на новый по программе <b>Trade In</b> ",
        img: "/media/icons/services/1.svg",
        link: 'https://alarm-motors.ru/trade-in?utm_medium=parking_bot&utm_source=telegram'
    },
]