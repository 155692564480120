<template>
    <section class="auto-new" >
        <v-container>
            <h2>
                Выгоды на новые автомобили
            </h2>
            <ul class="mb-8 mb-md-16  size-4 pl-5">
                <li>Кредит</li>
                <li>Лизинг</li>
                <li>Trade In</li>
                <li>Рассрочка</li>
                <li>Возможность миксовать условия под ваш запрос</li>
            </ul>
            <div>
                <div v-for="(item, index) of AutoNew" :key="index" class="auto-new__item">
                    <v-row class="justify-space-between align-center">
                        <v-col cols="12" md="3" class="pa-0">
                            <v-img :src="item.img" class="auto-new__img"/>
                        </v-col>
                        <v-col cols="12" md="3" class="pa-0">
                            <h4 class="auto-new__name mb-5 mb-md-0">{{ item.name }}</h4>
                        </v-col>
                        <v-col cols="12" md="3" class="pa-0">
                            <div class="auto-new__discount size-3 text-center mb-5 mb-md-0">{{ item.discount }}</div>
                        </v-col>
                        <!--<div class="d-flex justify-space-between mb-5">
                            <div class="auto-new__price --old">{{ item.oldPrice }}</div>
                            <div class="auto-new__price">{{ item.newPrice }}</div>
                        </div>-->
                        <v-col cols="12" md="3" class="pa-0">
                            <button class="btn">
                                <a :href="item.link" class="d-block">
                                    Получить выгоду
                                </a>
                            </button>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </v-container>
    </section>
</template>

<style lang="scss">
.auto-new {
    &__item {
        &:not(:last-child) {
            padding-bottom: 50px;
            border-bottom: 1px solid #ccc;
            @include md {
                padding-bottom: 0;
            }
        }
        @include md {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
    & ul {
        list-style: disc !important;
        & li {
            &:not(:last-child) {
                margin-bottom: 15px;
            }
        }
    }

    &__img {
        display: block;
        width: auto;
        height: 40px;
        margin: 40px auto;
        @include md {
            max-width: 230px;
        }
        @include lg {
            height: 80px;
            margin: 30px auto;
        }
    }

    &__name {
        text-align: center;
        font-weight: 700;
    }

    &__discount {
        font-weight: 700;
    }

    &__price {
        &.--old {
            text-decoration: line-through;
        }
    }

    .v-image__image--cover {
        background-size: contain;
    }

    & .btn {
        font-weight: 400;
        @include md {
            max-width: 210px;
            margin: 0 auto;
            display: block;
        }
        @include lg {
            max-width: 280px;
        }
    }
}
</style>

<script>
import AutoNew from "../js/auto-new";

export default {
    name: 'AutoNew',
    data() {
        return {
            AutoNew
        }
    },
}
</script>