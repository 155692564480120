<template>
    <section class="main-banners">

        <div v-for="(item, index) of Banners" :key="index"
             class="main-banners__banner --1 size-4">
            <span v-html="item.name" class="d-block"></span>
            <a :href="item.link" class="main-banners__link size-3"
               @click.prevent="scrollTo(item.link)"
            >{{ item.btn }}</a>


        </div>

    </section>
</template>

<script>
import Banners from "../js/banners";

export default {

    data() {
        return {
            Banners
        }
    },
    methods: {
        scrollTo(linkId) {
            //this.drawer = false;
            const el = document.getElementById(linkId);
            if (!el) return;
            window.scrollTo({
                top: el.offsetTop - 50,
                behavior: "smooth"
            });
        }
    },
}
</script>

<style lang="scss">
.main-banners {

    &__inner {
        //background-color: rgba(255, 255, 255, 0.6);
        //border-radius: 20px;
        //padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        @include sm {
            background-color: transparent;
        }
    }

    &__banner {
        height: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color: $colorBlack;
        //text-shadow: 4px 2px 10px #000;
        font-weight: 700;
        background-size: cover;
        background-position: center center;
        padding: 0 20px;
        @include md {
            padding: 0 40px;
        }

        &:first-child {
            background-image: url("~@/assets/banners/new-auto/new1.jpg");
            background-position: center left;
            align-items: end;
            text-align: end;
            border-bottom: 1px dashed $colorRed;
            @include sm {
                background-image: url("~@/assets/banners/new-auto/new1.jpg");
            }
            @include md {
                background-image: url("~@/assets/banners/new-auto/new1.jpg");
            }
        }

        &:nth-child(2) {
            background-image: url("~@/assets/banners/old-auto/old1.png");
            background-position: center right;
            align-items: start;
            border-bottom: 1px dashed $colorRed;
            @include sm {
                background-image: url("~@/assets/banners/old-auto/old1.png");
            }
            @include md {
                background-image: url("~@/assets/banners/old-auto/old1.png");
            }
        }

        &:nth-child(3) {
            background-position: center left;
            align-items: end;
            text-align: end;
            background-image: url("~@/assets/banners/service-offers/service1.jpg");
            @include sm {
                background-image: url("~@/assets/banners/service-offers/service1.jpg");
            }
            @include md {
                background-image: url("~@/assets/banners/service-offers/service1.jpg");
            }
        }

        @include sm {
            height: 220px;
        }

        @include lg {
            height: 340px;
            //flex-direction: row;
            //align-items: center;
            //justify-content: space-between;
            padding: 0 80px;
        }
        @include xl {
            height: 490px;
        }
    }

    &__link {
        //color: $colorRed !important;
        padding: 5px 10px;
        border: 1px solid $colorRed;
        border-radius: 20px;
        margin-top: 15px;
        @include sm {
            margin-top: 35px;
            padding: 10px 20px;
        }
    }
}
</style>