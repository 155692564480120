<template>
  <div class="dealer-centers-mobile">
      <v-container>
    <header class="dealer-centers-mobile__header ">
      <h2
        class="dealer-centers-mobile__title text-medium text-left mb-4"
      >
        Дилерские центры
      </h2>
      <p class="dealer-centers-mobile__subtitle mb-6">
        Мы всегда рады видеть вас в наших дилерских центрах.
      </p>
    </header>
    <v-expansion-panels class="dealer-centers-mobile__list">
      <v-expansion-panel v-for="item in items" :key="item.title">
        <v-expansion-panel-header>
          <div>
            <h3 class="text-h6 mb-1">{{ item.name }}</h3>
            <p class="mb-0 ">{{ item.address }}</p>
            <p class="mb-0">{{ item.nameINN }}</p>
            <p class="mb-0">{{ item.INN }}</p>
          </div>
          <template #actions>
            <v-btn icon large>
                <svg class="dealer-centers__item-icon" width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="18" cy="18" r="18" fill="#F2F3F7"/>
                    <path d="M16 22L20 18L16 14" stroke="#848AA2" stroke-width="2"/>
                </svg>
            </v-btn>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <DealerDetail :item="item" />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
      </v-container>
  </div>
</template>
<script>
  import DealerDetail from "./DealerCentersMobileDetail.vue";

  export default {
    name: "MapDealerCentersMobile",

    components: {
      DealerDetail,
    },

    props: {
      items: {
        type: Array,
        required: true,
      },
    },
  };
</script>
<style lang="scss">
  .dealer-centers-mobile {
    &__header {
      padding: 0 10px;
    }

    .v-expansion-panel-header--active {
      .base-icon {
            transform: rotate(-90deg);
             circle {
          //fill: $primary;
        }
        path {
         // stroke: $white;
        }
      }
    }
  }
  body .info--text.inn-text {
    font-size:10px;
  }
</style>
