export default [
    {
        name: "Аларм-Моторс Озерки",
        address: "Выборгское шоссе, д. 27 к. 1",
        phone: "8 (812) 385-27-24",
        navLink: "https://yandex.ru/maps/-/CCUjY-t6~B",
        hours: "Ежедневно 9:00–21:00",
        //img: "/media/images/models/TUGELLA/complects/mars-red.png",
        coords: [60.060447, 30.306103],
    },
    {
        name: "Аларм-Моторс Озерки",
        address: "Выборгское шоссе, д. 23 к. 1",
        phone: "8 (812) 385-27-24",
        navLink: "https://yandex.ru/maps/2/saint-petersburg/house/vyborgskoye_shosse_27k1/Z0kYdQdmSEUEQFhqfXx3cXhnYw==/?ll=30.306103%2C60.060447&z=16.67",
        hours: "Ежедневно 9:00–21:00",
        //img: "/media/images/models/TUGELLA/complects/mars-red.png",
        coords: [60.059728, 30.306562],
    },
    {
        name: "Аларм-Моторс Север",
        address: "Коломяжский проспект, д. 18",
        phone: "8 (812) 385-27-24",
        navLink: "https://yandex.ru/maps/2/saint-petersburg/chain/alarm_motors/3215903655/?ll=30.298633%2C59.996938&sll=30.321084%2C60.046818&sspn=0.123253%2C0.032624&utm_source=main_stripe_big&z=16",
        hours: "Ежедневно 9:00–21:00",
        //img: "/media/images/models/TUGELLA/complects/mars-red.png",
        coords: [59.996717, 30.29845],
    },
    {
        name: "Аларм-Моторс Лахта",
        address: "ул. Савушкина, д. 108",
        phone: "8 (812) 385-27-24",
        navLink: "https://yandex.ru/maps/2/saint-petersburg/?ll=30.255943%2C59.922022&mode=search&oid=1493870622&ol=biz&sll=30.315635%2C59.938951&sspn=0.528717%2C0.183983&text=%D0%B0%D0%BB%D0%B0%D1%80%D0%BC%20%D0%BC%D0%BE%D1%82%D0%BE%D1%80%D1%81%20%D0%9A%D0%B8%D0%B0&utm_source=main_stripe_big&z=11",
        hours: "Ежедневно 9:00–21:00",
        //img: "/media/images/models/TUGELLA/complects/mars-red.png",
        coords: [59.922022, 30.255943],
    },
    {
        name: "Аларм-Моторс Юг",
        address: "пр. Маршала Жукова, д. 51",
        phone: "8 (812) 385-27-24",
        navLink: "https://yandex.ru/maps/org/alarm_motors_yug_ofitsialny_cervis_ford/1059965124/?ll=30.212975%2C59.846916&mode=search&sll=30.212975%2C59.846916&sspn=0.015514%2C0.004131&text=%D0%90%D0%BB%D0%B0%D1%80%D0%BC%20%D0%9C%D0%BE%D1%82%D0%BE%D1%80%D1%81%20%D0%AE%D0%B3%D0%BE-%D0%97%D0%B0%D0%BF%D0%B0%D0%B4%20%E2%80%94%20%D0%BE%D1%84%D0%B8%D1%86%D0%B8%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D0%B4%D0%B8%D0%BB%D0%B5%D1%80%20%D1%84%D0%BE%D1%80%D0%B4&utm_source=main_stripe_big&z=16.99",
        hours: "Ежедневно 9:00–21:00",
        //img: "/media/images/models/TUGELLA/complects/mars-red.png",
        coords: [59.846704, 30.213067],
    },
    {
        name: "Кузовной цех Екатерининский",
        address: "пр. Маршала Блюхера, д. 58 к. 2",
        phone: "8 (812) 385-27-24",
        navLink: "https://yandex.ru/maps/-/CCUjY2Tm9C",
        hours: "Ежедневно 9:00–21:00",
        //img: "/media/images/models/TUGELLA/complects/mars-red.png",
        coords: [59.976139, 30.442171],
    },
]