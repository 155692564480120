<template>

    <div class="footer__copy-info">
        <p
            v-for="(item, index) in visibleList"
            v-html="item"
            :key="index"
            class="size-2"
        ></p>
    </div>

</template>
<script>

export default {
    name: "FooterCopy",

    props: {
        loadedItems: {
            type: Number,
            default: 1,
        },
        showAll: {
            type: Boolean,
            default: false,
        },
    },

    data: () => ({
        list: [
            `
      Обращаем ваше внимание на то, что вся представленная на сайте информация,
      касающаяся комплектаций, технических характеристик, цветовых сочетаний,
      стоимости автомобилей, запасных частей, сервисного обслуживания, а также
      акций и специальных предложений носит информационный характер и ни при
      каких условиях не является публичной офертой, определяемой положениями
      Статьи 437 (2) Гражданского кодекса Российской Федерации. Для получения
      подробной информации, пожалуйста, обращайтесь к сотрудникам дилерских
      центров «Аларм-Моторс».
    `,
            `
      Используя данный сайт, Вы даете согласие на использование файлов cookie, данных об IP-адресе и местоположении, помогающих нам сделать его удобнее для Вас.
      `,
            `
      Использование <a style='color:red !important;border-bottom-color:red !important;' tagret='_blank' href='/confirm_alarm.docx'>персональных данных</a> в группе компаний «Аларм-Моторс»
      полностью соответствует федеральному закону «О персональных данных» №
      261-ФЗ с учетом изменений от 25.07.2011 г. Пользуясь сервисами обратной
      связи на нашем сайте или предоставляя персональные данные по телефонам,
      указанным на сайте, Вы выражаете согласие на обработку, хранение и
      использование Ваших персональных данных в целях осуществления
      коммуникации.
    `,
            `
      Аларм-Моторс ведет деятельность на территории Российской Федерации в
      соответствии с законодательством Российской Федерации. Предлагаемые
      товары/работы/услуги доступны к получению на территории Российской
      Федерации. Мониторинг потребительского поведения субъектов, находящихся за
      пределами Российской Федерации, не ведется.`,
        ],
    }),

    computed: {
        visibleList() {
            if (this.showAll) return this.list;
            return this.list.slice(0, this.loadedItems);
        },
    },
};
</script>

<style lang="scss">
.footer {
    &__copy {
        &-alarm {
            font-size: 18px;
            line-height: 23px;
        }

        &-phone {
            width: fit-content;
        }

        &-logo {
            svg {
                width: 75px;
                height: 50px;
            }
        }

        &-playnext {
            width: fit-content;
        }

        &-separator {
            height: 2px;
            border: 0;
            margin: 45px 0;

            //background: $accent;
        }
    }
}
</style>
