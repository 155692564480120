<template>
    <div class="header-menu">
        <div class="header-menu__wrapper-md d-none d-lg-block">
            <div class="d-flex">
                <div v-for="{ link, text } in items" :key="text" class="header-menu__item">
                    <a :href="link" class="header-menu__link size-3" @click.prevent="scrollTo(link)">
                        {{ text }}
                    </a>
                </div>
            </div>
        </div>

            <v-navigation-drawer
                v-model="drawer"
                app
                temporary
                tag="nav"
                overlay-color="#11131C"
                overlay-opacity="0.5"
                :height="`calc(100vh - ${$vuetify.application.top}px)`"
                :width="`100%`"
                class="d-lg-none"
            >
                <v-container>
                <div class="header-menu__wrapper d-lg-none">

                    <ul class="reset--list-style">
                        <li v-for="{ link, text } in items" :key="text">
                            <a :href="link" class="header-menu__link size-3" @click.prevent="scrollTo(link)">
                                {{ text }}
                            </a>
                        </li>
                    </ul>

                </div>
        </v-container>
            </v-navigation-drawer>

    </div>
</template>

<script>
export default {
    name: "MainHeaderMenu",

    data: () => ({
        drawer: false,
    }),

    props: {
        show: {
            type: Boolean,
            default: false,
        },
        items: {
            type: Array,
            required: true,
        },
    },

    watch: {
        show: {
            handler(val) {
                this.drawer = val;
            },
            immediate: true,
        },
        drawer(val) {
            this.$emit("update:show", val);
        },
    },

    methods: {
        scrollTo(linkId) {
            this.drawer = false;
            const el = document.getElementById(linkId);
            if (!el) return;
            window.scrollTo({
                top: el.offsetTop - 50,
                behavior: "smooth"
            });
        }
    },
};
</script>

<style lang="scss">
.header-menu {
    @include lg {
        margin: 0 30px;
    }

    & .v-navigation-drawer {
        padding-top: 65px;
        padding-bottom: 20px;
        z-index: -1;
        @include lg {
            padding-top: unset;
        }
    }

    &__wrapper {
        //padding: $deafult-space-tablet $default-block-space;
        & ul {
            list-style: none;
        }
        & li {
            padding: 10px;
        }
    }

    &__item {
        //max-width: 190px;
        &:not(:last-child) {
            margin-right: 15px;
        }
    }

    &__link {
        display: block;
        &:hover {
            text-decoration: underline;
        }
    }
}
</style>