<template>
    <yandex-map
            class="big-map"
            :style="`height: ${cssHeight};`"
            :coords="coords"
            @map-was-initialized="onMapInited"
            :controls="[]"
            :zoom="zoom"
    >
        <ymap-marker
                v-for="item in markers"
                :key="item.title"
                :marker-type="item.type || 'Placemark'"
                :coords="item.coords"
                :hint-content="item.address || 'Аларм-Моторс'"
                :icon="icon"
                :markerId="item.address"
                @mouseenter="onMouseenter(item)"
                @mouseleave="onMouseleave()"
                @click="onClick(item)"
        />
    </yandex-map>
</template>
<script>


    const ZOOM_CLICK = 18;
    export default {
        name: "HomeBigMap",

        props: {
            coords: {
                type: Array,
                default() {
                    return [54.82896654088406, 39.831893822753904];
                },
            },
            markers: {
                type: Array,
                required: true,
            },
            zoom: {
                type: Number,
                default: 11,
            },
            offsetX: {
                type: Number,
                default: 0,
            },
            offsetY: {
                type: Number,
                default: 0,
            },
        },

        data: () => ({
            map: null,
        }),

        watch: {
            /*map(ymap) {
              if (!ymap) return;
              ymap.behaviors.disable("scrollZoom");
              this.setBounds();
            },*/
        },

        computed: {
            isSMAndDown() {
                // TODO: исправить этот колхоз на vuetify breakpoint booleans
                console.log("Width: ", window.innerWidth);
                return window.innerWidth < 780;
            },
            cssHeight() {
                switch (this.$vuetify.breakpoint.name) {
                    case "xs":
                        return "680px";
                    case "sm":
                        return "760px";
                    case "md":
                        return "990px";
                    case "lg":
                        return "990px";
                    case "xl":
                        return "990px";
                }
            },
            icon() {
                return {
                    layout: "default#imageWithContent",
                    imageHref: require(`@/assets/map/map-icon.png`),
                    imageSize: [33, 37],
                    imageOffset: [0, 0],
                };
            },
        },

        methods: {
            onMapInited(map) {
                this.map = map;
                if (!map) return;
                map.behaviors.disable("scrollZoom");
                this.setBounds();
            },
            setBounds() {
                setTimeout(() => {
                    if ( !this.map.geoObjects || this.map.geoObjects.getLength() === 0 ) return;

                    if (this.map.geoObjects.getLength() === 1) {
                        //console.log("Geo object: ", this.map.geoObjects.get(0));
                        // todo не сообразил, как взять коордсы одной точки...
                        // смещаем центр чуть вправо, чтобы плашка не закрывала точку на карте
                        let coords = this.map.geoObjects.getBounds()[0];

                        // TODO: понять на каком брикпоинте НЕ нужно смещать точку на карте врпаво (ккогда она одна)
                        if ( !this.isSMAndDown ) coords[1] -= 0.13;
                        this.map.setCenter(coords, 11);
                    } else {
                        this.map.setBounds(this.map.geoObjects.getBounds(), {
                            checkZoomRange: true,
                            duration: 250,
                            //zoomMargin: 1000
                        });
                    }
                }, 300);
            },
            onClick(item) {
                this.map
                    .setCenter(item.coords, ZOOM_CLICK)
                    .then((e) => {
                        if (e) console.error(e);
                        else
                            this.setZoom(ZOOM_CLICK).then(() => {
                                this.$emit("marker-click", item);
                            });
                    })
                    .catch((e) => console.error(e));
            },
            setZoom(zoom) {
                let pixelCenter = this.map.getGlobalPixelCenter();
                this.map.setGlobalPixelCenter([
                    pixelCenter[0] + this.offsetX,
                    pixelCenter[1] + this.offsetY,
                ]);
                return this.map.setZoom(zoom);
            },
            onMouseenter(item) {
                this.$emit('mouseover', item);
            },
            onMouseleave() {
                this.$emit('mouseleave');
            },
        },

        mounted() {
        },
    };
</script>
<style lang="scss">
    .big-map {
        width: 100%;
    }
</style>
