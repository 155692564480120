export default [
    {
        name: "Выгода на новые<br> автомобили",
        btn: "Подробнее",
        img: "/media/banners/new-auto/2560х880.jpg",
        link: "new"
    },
    {
        name: "Выгода на<br> автомобили<br class='d-md-none'> с пробегом",
        btn: "Подробнее",
        img: "/media/banners/old-auto/2560х880.jpg",
        link: "old"
    },
    {
        name: "Сервисные<br> спец. предложения",
        btn: "Подробнее",
        img: "/media/banners/service-offers/2560х880.jpg",
        link: "services"
    },
]