<template>
    <layout-main/>
</template>

<style lang="scss">

.v-application {
    line-height: inherit;
    font-family: $font, sans-serif;

    & ul {
        padding: 0;
        list-style: none;
    }

    & a {
        color: unset !important;
        text-decoration: none;
        transition: all 0.2s ease-in;
    }

    & .main-banners__link {
        color: $colorBlack !important;

        &:hover {
            //border: 1px solid $colorBlack;
            color: $colorRed !important;
            text-shadow: 4px 2px 10px #fff;
        }
    }

    & p {
        margin-bottom: 0;
    }

    .v-toolbar__content, .v-toolbar__extension {
        padding: 0;
    }

    .container {
        max-width: unset;
        width: 100%;
        margin: 0 auto;
        padding: 0 40px;
        @include md {
            max-width: 960px;
            padding: 0 20px;
        }
        @include lg {
            max-width: 1280px;
        }
        @include xl {
            max-width: 1860px;
        }
    }
}

body {
    font-size: 18px;
    line-height: 100%;
    @include md {
        font-size: 22px;
    }
    @include lg {
        font-size: 30px;
    }
}

h2 {
    text-align: center;
    margin-bottom: 30px;
    @include md {
        margin-bottom: 70px;
    }
}

button {
    width: 100%;
}

.btn {
    width: 100%;
    background: $colorRed;
    color: $colorWhite;
    font-weight: 700;
    padding: 7px 10px;
    border: 2px solid transparent;
    transition: all 0.2s ease-in;
    border-radius: 10px;

    &:hover {
        background: $colorWhite;
        color: $colorRed;
        border: 2px solid $colorRed;
    }

    &.--grey {
        background: $colorGrey;
        color: $colorBlack;

        &:hover {
            background: $colorBlack;
            color: $colorGrey;
            border: 2px solid $colorGrey;
        }
    }

    @include md {
        padding: 15px;
        max-width: 450px;
        margin: 0 auto;
        display: block;
    }
    @include lg {
        max-width: 590px;
    }
}

</style>

<script>

import LayoutMain from "@/layout/layout-main";

export default {
    name: 'App',
    components: {LayoutMain},
    data: () => ({
        //
    }),
};
</script>
