<template>
    <section class="offer">

        <v-container>
            <div>
                <h2>Не нашли что искали?</h2>
                <v-row class="align-center">
                    <v-col cols="12" lg="6" class="d-none d-lg-block">
                        <v-img src="~@/assets/man-form.png"/>
                    </v-col>
                    <v-col cols="12" lg="6" class="offer__form">
                        <h3 class="text-center mb-8 mb-lg-16">Позвоните нам <br>
                            <a href="tel:88123852724">8 (812) 385-27-24</a><br>
                            или оставьте заявку
                        </h3>
                        <v-form @submit.prevent="submit">
                            <div class="offer__row">
                                <label class="offer__label">Ваше имя</label>
                                <div class="offer__inner">
                                    <input v-model="form.name" class="offer__input offer__name" type="text" id="your_name" name="your_name">
                                </div>
                            </div>
                            <div class="offer__row">
                                <label class="offer__label">Телефон</label>
                                <div class="offer__inner">
                                    <input v-model="form.phone" id="your_phone" class="offer__input offer__phone" type="tel"
                                           name="your_phone">
                                </div>
                            </div>
                            <div class="offer__row">
                                <label class="offer__label">Комментарий</label>
                                <div class="offer__inner">
                                    <input v-model="form.comment" class="offer__input offer__comment"
                                           id="offer__comment" name="offer__comment" type="text">
                                </div>
                            </div>

                            <p v-if="error" class="mt-3 mb-3" v-html="error"></p>

                            <button class="btn submit mb-4" type="submit">
                                <pulse-loader v-if="isLoading" :loading="true" color="#fff" size="5px"/>
                                <span v-else>Отправить заявку</span>
                            </button>


                            <p class="personal_offer__terms">Нажимая на кнопку «Отправить заявку», я даю
                                согласие на <a href="https://alarm-motors.ru/legal/">обработку персональных
                                    данных</a>
                            </p>

                        </v-form>
                    </v-col>
                </v-row>
            </div>
        </v-container>

        <v-dialog v-model="isSubscribed" persistent max-width="900px" @click:outside="closeDialog">
            <v-card class="pa-5 pa-md-5 offer-success">
                <v-card-title class="justify-center flex-column">
                    <h3 class="text-center mb-4" slot="header">Спасибо!</h3>
                    <p class="text-center size-4">Ваша заявка принята.</p>
                </v-card-title>

                <v-card-actions class="d-block">
                    <button class="btn"  @click="closeDialog">
                        Закрыть
                    </button>
                </v-card-actions>


            </v-card>
        </v-dialog>
    </section>
</template>

<script>

import PulseLoader from 'vue-spinner/src/PulseLoader';
import axios from "axios";
import Config from "@/Config";

export default {
    components: {PulseLoader},
    data() {
        return {
            isSubscribed: false,
            form: {
                name: "",
                phone: "",
                comment: "",
            },
            error: "",
            isLoading: false,
        }
    },
    methods: {
        closeDialog() {
            this.isSubscribed = false;
        },
        submit() {

            const reName = /^.{2,}$/;
            if (!reName.test(String(this.form.name).toLowerCase())) {
                return this.error = "Пожалуйста, введите Ваше имя";
            }

            const phone = this.form.phone.replace(/\D/, "");
            const rePhone = /^\d{11}$/;
            if (!rePhone.test(phone)) {
                return this.error = "Пожалуйста, введите Ваш номер телефона";
            }

            /*const reComment = /^.{5,}$/;
            if (!reComment.test(String(this.form.comment).toLowerCase())) {
                return this.error = "Пожалуйста, введите Ваш комментарий";
            }*/

            this.error = false;
            this.isLoading = true;

            return axios.post(Config.FORM_URL, this.form)
                .then((response) => {
                    console.log('API Response: ', response);
                    if (response.data?.error) {
                        if (response.data.code === Config.ERROR_CODE_EXISTS) this.error = "Такой номер телефона уже зарегистрирован.<br />Регистрация возможна только один раз."
                        else if (response.data.code === Config.ERROR_CODE_WRONG_PARAMS) this.error = "Отправлены неверные параметры.<br />Пожалуйста, проверьте и попробуйте снова."
                        else this.error = "Произошла неизвестная ошибка.<br />Пожалуйста, попробуйте отправить еще раз.";

                    } else {
                        this.isSubscribed = true;

                    }
                })
                .catch((error) => {
                    console.warn('API request error: ', error);
                    this.error = error;
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
    },
}
</script>

<style lang="scss">
.offer {
    &__row {
        margin-bottom: 35px;
    }

    &__form {
        @include xl {
            padding: 100px;
        }
    }

    &__input {
        margin-top: 10px;
        width: 100%;
        height: 60px;
        background-color: $colorGreyLight;
        padding-left: 20px;
    }
}
</style>