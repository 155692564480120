<template>
    <section class="service-offers">
        <v-container>
            <h2>Сервисные спец.предложения</h2>
            <ul class="mb-8 mb-md-16  size-4 pl-5">
                <li>Большой склад запчастей в наличии</li>
                <li>Ремонт и диагностика любой сложности</li>
                <li>Пять сервисных центров по городу</li>
            </ul>
            <div class="service-offers__list text-center mb-8 mb-md-16">
                <v-row class="justify-center">
                    <v-col cols="6" md="3" v-for="(item, index) of ServiceOffers"
                           :key="index">
                        <a
                            :href="item.link"
                            class="service-offers__item">
                            <div class="service-offers__content">
                                <img :src="item.img" class="service-offers__img"/>
                            </div>
                            <div class="service-offers__text">
                                {{ item.name }}
                            </div>
                        </a>
                    </v-col>
                </v-row>
            </div>

            <popup>
                <button class="btn" type="button">Получить персональное предложение</button>
            </popup>


        </v-container>
    </section>
</template>

<script>
import ServiceOffers from "../js/service-offers";
import Popup from "@/components/popup";

export default {
    name: 'service-offers',
    components: {Popup},
    data() {
        return {
            ServiceOffers
        }
    },
}
</script>


<style lang="scss">
.service-offers {
    &__list {

    }

    & ul {
        list-style: disc !important;

        & li {
            &:not(:last-child) {
                margin-bottom: 15px;
            }
        }
    }

    &__item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &:hover {
            color: $colorRed !important;

            & .service-offers__content {
                //border: 2px solid $colorRed;
            }
        }
    }



    &__text {
        margin-top: 15px;
    }

    &__img {
        height: 120px;
        width: 120px;
    }
}
</style>