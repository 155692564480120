import Vue from "vue";
import YmapPlugin from "vue-yandex-maps";

//import { YANDEX_MAP_KEY } from "../Config";

const settings = {
  apiKey: "a8285b9a-7ac3-4de0-ba07-f95bd8818390",
  lang: "ru_RU",
  coordorder: "latlong",
  version: "2.1",
};

Vue.use(YmapPlugin, settings);
