<template>
    <v-app-bar
        class="header d-flex flex-column justify-center"
        app
        flat
        color="white"
        elevate-on-scroll
        style="z-index: 200"
        :height="headerHeight"
    >
        <v-container class="d-flex align-center justify-space-between">
            <div class="d-flex align-center">
                <a href="https://alarm-motors.ru/" target="_blank" class="header__logo">
                    <img src="~@/assets/logo.gif" alt="Alarm Motors Logo" class="alarm-logo"/>
                </a>
                <div class="header__text size-1 ml-4">Автодилер №1<br class="d-lg-none"> в Санкт-Петербурге</div>
            </div>
            <HeaderMenu
                class="d-none d-lg-block"
                :show.sync="show"
                :items="navItems"
                :style="`margin-top: ${$vuetify.application.top}px`"
            />
            <div class="d-flex align-center">
                <a class="phone_link mr-4 size-3" href="tel:88123852724">
                    <svg class="ic ic--phone-m d-md-none">
                        <use xlink:href="../assets/sprite.svg#phone-m"></use>
                    </svg>
                    <span>8 (812) 385-27-24</span>
                </a>
                <v-app-bar-nav-icon
                    width="35px"
                    height="35px"
                    color="secondary"
                    @click.stop="toggleMenuVisibility"
                    class="d-lg-none"
                >
                    <v-icon v-show="!show" large>mdi-menu</v-icon>
                    <v-icon v-show="show" large>mdi-close</v-icon>
                </v-app-bar-nav-icon>
            </div>
        </v-container>
        <HeaderMenu
            class="d-lg-none"
            :show.sync="show"
            :items="navItems"
            :style="`margin-top: ${$vuetify.application.top}px`"
        />
    </v-app-bar>
</template>

<script>
import HeaderMenu from "../components/header-menu.vue";

export default {
    name: "TheHeader",

    components: {
        HeaderMenu,
    },
    data: () => ({
        show: false,
    }),

    computed: {
        isLgAndDown() {
            return this.$vuetify.breakpoint.lgAndDown;
        },
        headerHeight() {
            return this.isLgAndDown ? "auto" : "auto";
        },
        navItems() {
            return [
                {
                    link: "new",
                    text: "Выгода на новые автомобили",
                },

                {
                    link: "old",
                    text: "Выгода на автомобили с пробегом",
                },
                {
                    link: "services",
                    text: "Сервисные спец.предложения",
                },
                {
                    link: "contacts",
                    text: "Как доехать",
                },
            ];
        },
    },

    methods: {
        toggleMenuVisibility() {
            this.show = !this.show;
        },
    },
}
</script>

<style lang="scss">
.v-app-bar.v-app-bar--fixed {
    @include lg {
        position: unset;
    }
}
.header {
    padding: 10px 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background-color: #ffffff;
    border-bottom: 1px solid rgba(122, 120, 120, 0.2);
    @include lg {
        padding: 30px 0;
        position: unset;
    }


    &__logo {

        & img {
            //max-width: 65px;
            //max-height: 39px;
            width: 38px;
            height: 24px;
            @include lg {
                width: 68px;
                height: 44px;
            }
        }
    }

    &__text {

    }

    & .phone_link {
        border: none;
        overflow: hidden;
        border-radius: 50%;
        width: 1.875rem;
        height: 1.875rem;
        background-color: #000;
        text-align: center;
        @include md {
            border-radius: unset;
            width: 100%;
            height: unset;
            background-color: unset;
            margin-right: 0;
            overflow: unset;
            white-space: nowrap;
        }
        & span {
            transition: all 0.2s ease-in;
            &:hover {
                color: red;
            }
        }
    }

    & .ic--phone-m {
        fill: $colorWhite;
        margin-top: 5px;
    }
}
</style>