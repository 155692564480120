<template>
    <section class="dealer-detail pa-12 white">
        <header class="dealer-detail__header mb-3">
            <a
                href="/"
                @click.prevent="goBack"
                class="d-flex align-center dealer-detail__back-link"
            >
                <svg class="dealer-detail__item-icon mr-2" width="36" height="36" viewBox="0 0 36 36" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <circle cx="18" cy="18" r="18" fill="#F2F3F7"/>
                    <path d="M16 22L20 18L16 14" stroke="#848AA2" stroke-width="2"/>
                </svg>
                <p class="mb-0 size-5">Все дилерские центры</p>
            </a>
            <h2 class="dealer-detail__title mb-0 mt-3">{{ item.title }}</h2>
        </header>
        <v-img height="50px" :src="item.img" class="mb-3"/>
        <div class="d-flex justify-space-between mb-8">
            <v-col cols="7" class="pa-0">
                <h5 class="dealer-detail__address size-5">
                    {{ item.address }}
                </h5>
            </v-col>
            <v-col cols="5" class="pa-0">
                <a :href="`tel:${item.phone}`" class="dealer-detail__phone ml-1 justify-end d-flex">
                    {{ item.phone | phone("($1) $2-$3-$4") }}</a>
            </v-col>
        </div>
        <!--    <div class="d-flex justify-space-between dealer-detail__work-time-wrap">
              <v-col cols="6" class="pa-0">
              <a :href="item.yandexLink" target="_blank">Перейти в Яндекс.Навигатор</a>
              </v-col>
              <v-col cols="6" offset="1" class="pa-0  d-flex text-end">
              <a
                class="mb-0 dealer-detail__work-time pb-2 ml-1"
                href="#"
                @click.prevent=""
              >
                <span>{{ item.workTime }}</span>

                <ul
                  class="reset&#45;&#45;list-style elevation-2 pa-2 dealer-detail__work-time-list white"
                >
                  <li
                    v-for="{ label, value } in item.workTimeList"
                    :key="label"
                    class="mb-1 text-left"
                  >
                    <p class="mb-0 info&#45;&#45;text" v-html="label"></p>
                    <p class="mb-0 info&#45;&#45;text" v-html="value"></p>
                  </li>
                </ul>
              </a>
              </v-col>
            </div>-->
        <div class="d-flex justify-space-between dealer-detail__work-time-wrap mb-8">
            <a :href="item.navLink" target="_blank">Перейти в Яндекс.Навигатор</a>
            <a
                class="mb-0 dealer-detail__work-time pb-2 ml-1"
                href="#"
                @click.prevent=""
            >
                <span class="text-no-wrap">{{ item.hours }}</span>
            </a>
        </div>

        <popup>
            <button class="btn ma-0">
                Получить персональное предложение
            </button>
        </popup>




    </section>
</template>
<script>
import AutoNew from "@/js/auto-new";
import Popup from "@/components/popup";


export default {
    name: "DealerCenters",
    components: {Popup},
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    computed: {
        islgAndDown() {
            return this.$vuetify.breakpoint.lgAndDown;
        },
        islgAndUp() {
            return this.$vuetify.breakpoint.xlOnly;
        },
        btnText() {
            return this.islgAndDown
                ? "Получить предложение"
                : "Получить персональное предложение";
        },
    },

    methods: {
        goBack() {
            this.$emit("show-dealers");
        },
    },
};
</script>

<style lang="scss">
.dealer-detail {
    max-width: 710px;

    @include lg {
        max-width: 910px;
    }

    //border-radius: $border-radius-root;

    &__title {
        //@include font("big");
    }

    &__subtitle {
    }

    p {
        //@include font("x-small");
    }

    &__item-icon {
        transform: rotate(180deg);
    }

    &__back-link {
        &:hover,
        &:focus {
            circle {
                //fill: $primary;
            }

            path {
                //stroke: $white;
            }
        }
    }

    &__phone {
        //@include font("x-normal");
    }

    &__work-time {
        position: relative;
    }

    &__work-time-list {
        position: absolute;
        top: 30px;
        right: -15px;
        z-index: 10;
        width: fit-content;

        //width: 170px;

        visibility: hidden;
        opacity: 0;
        pointer-events: none;

        //border-radius: $border-radius-root;
    }

    &__work-time:hover &__work-time-list,
    &__work-time:focus &__work-time-list {
        visibility: visible;
        opacity: 1;
        pointer-events: all;
    }
}
</style>
