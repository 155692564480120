<template>
    <div class="home">
        <main-banners/>
        <auto-new id="new"/>
        <auto-old id="old"/>
        <service-offers id="services"/>
        <services/>
        <offer/>
        <MainMap id="contacts"/>

    </div>
</template>

<script>
import TheHeader from "../components/the-header";
import MainBanners from "@/components/main-banners";
import AutoNew from "@/components/auto-new";
import AutoOld from "@/components/auto-old";
import ServiceOffers from "@/components/service-offers";
import Services from "@/components/services";
import Offer from "@/components/offer";
import MainMap from "@/components/map-dealers/MainMapDealsers.vue";

export default {
    name: 'Home',

    components: {MainMap, Offer, Services, ServiceOffers, AutoOld, AutoNew, MainBanners, TheHeader},
}
</script>

<style lang="scss">
.home {
    & section {
        margin: 50px 0;
        @include md {
            margin: 100px 0;
        }
        @include lg {
            margin: 130px 0;
        }
    }
    & .main-banners{
        margin-top: 0;
    }
    & .centers {
        margin-bottom: 0;
    }
}
</style>
