export default [
    {
        name: "Комплексная диагностика за 899 руб.",
        img: "/media/icons/service-offers/1.svg",
        link: 'https://alarm-motors.ru/hot/kompleksnaia-diagnostika-so-skanerom-899-rub-30-na-rekomendovannye-raboty?utm_medium=parking_bot&utm_source=telegram'
    },
    {
        name: "Скидка до 50% на первый визит на сервис",
        img: "/media/icons/service-offers/3.svg",
        link: 'https://alarm-motors.ru/hot/davaite-znakomitsya-skidka-na-pervyi-vizit?utm_medium=parking_bot&utm_source=telegram'
    },
    {
        name: "Постгарантийный сервис со скидкой до 40%",
        img: "/media/icons/service-offers/4.svg",
        link: 'https://alarm-motors.ru/hot/vygody-na-postgarantiinyi-servis?utm_medium=parking_bot&utm_source=telegram'
    },
    {
        name: "Выгода 3 000 руб. на керамическое покрытие",
        img: "/media/icons/service-offers/2.svg",
        link: 'https://alarm-motors.ru/hot/vygoda-3000-rub-na-keramicheskoe-pokrytie-kuzova?utm_medium=parking_bot&utm_source=telegram'
    },
]