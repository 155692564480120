export default {
    FORM_URL: "/submit.php",

    //in _variables.scss, config.js and plugins/vuetify.js
    ERROR_CODE_FORBIDDEN: "403",
    ERROR_CODE_NOT_FOUND: "404",
    ERROR_CODE_EXISTS: "EXISTS",
    ERROR_CODE_WRONG_PARAMS: "WRONG_PARAMS",

    PHONE: "+7 (812) 385-27-24"
}