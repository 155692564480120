<template>
    <div class="popup">
        <v-dialog v-model="dialog" persistent max-width="900px" @click:outside="closeDialog">
            <template v-slot:activator="{ on, attrs }">
                <button v-bind="attrs" v-on="on" :class="class1">
                    <slot name="default"></slot>
                </button>
            </template>
            <div v-if="isSubscribed" class="py-15 popup-success">
                <h3 class="text-center mb-3" slot="header">Спасибо!</h3>
                <p class="text-center size-4 mb-4 popup__title-span">Ваша заявка принята.</p>
                <button class="btn" @click="closeDialog">
                    Закрыть
                </button>
            </div>
            <v-card v-else class="pa-5 pa-md-5 popup-form">
                <v-card-title class="justify-center flex-column">
                    <h2 class="mb-lg-10">Заказ звонка</h2>
                    <span class="size-3 popup__title-span">
                        Позвоните нам по номеру<br>
                        <strong><a href="tel:88123852724">8 (812) 385-27-24</a></strong>
                        или оставьте свой телефон<br> и мы свяжемся с вами в ближайшее время
                    </span>
                </v-card-title>

                <v-card-actions class="d-block">
                    <v-spacer></v-spacer>

                    <v-form @submit.prevent="submit">
                        <div class="offer__row">
                            <label class="offer__label">Ваше имя</label>
                            <div class="offer__inner">
                                <input v-model="form.name" class="offer__input offer__name" type="text" id="your_name" name="your_name">
                            </div>
                        </div>
                        <div class="offer__row">
                            <label class="offer__label">Телефон</label>
                            <div class="offer__inner">
                                <input v-model="form.phone" id="your_phone" class="offer__input offer__phone" type="tel"
                                       name="your_phone">
                            </div>
                        </div>

                        <p v-if="error" class="mt-3 mb-3" v-html="error"></p>

                        <button class="btn submit mb-4" type="submit">
                            <pulse-loader v-if="isLoading" :loading="true" color="#fff" size="5px"/>
                            <span v-else>Отправить заявку</span>
                        </button>


                        <p class="personal_offer__terms">Нажимая на кнопку «Отправить заявку», я даю
                            согласие на <a href="https://alarm-motors.ru/legal/">обработку персональных
                                данных</a>
                        </p>
                    </v-form>

                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>


</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader';
import axios from "axios";
import Config from "@/Config";

export default {
    name: "Popup",
    components: {PulseLoader},
    props: {
        class1: String,
    },

    data() {
        return {
            dialog: false,
            isSubscribed: false,
            form: {
                name: "",
                phone: "",
                comment: "",
            },
            error: "",
            isLoading: false,
            showModal: false
        }
    },

    methods: {
        closeDialog() {
            this.dialog = false;
        },
        submit() {

            const reName = /^.{2,}$/;
            if (!reName.test(String(this.form.name).toLowerCase())) {
                return this.error = "Пожалуйста, введите Ваше имя";
            }

            const phone = this.form.phone.replace(/\D/, "");
            const rePhone = /^\d{11}$/;
            if (!rePhone.test(phone)) {
                return this.error = "Пожалуйста, введите Ваш номер телефона";
            }


            this.error = false;
            this.isLoading = true;

            return axios.post(Config.FORM_URL, this.form)
                .then((response) => {
                    console.log('API Response: ', response);
                    if (response.data?.error) {
                        if (response.data.code === Config.ERROR_CODE_EXISTS) this.error = "Такой номер телефона уже зарегистрирован.<br />Регистрация возможна только один раз."
                        else if (response.data.code === Config.ERROR_CODE_WRONG_PARAMS) this.error = "Отправлены неверные параметры.<br />Пожалуйста, проверьте и попробуйте снова."
                        else this.error = "Произошла неизвестная ошибка.<br />Пожалуйста, попробуйте отправить еще раз.";

                    } else {
                        this.isSubscribed = true;
                        setTimeout(() => {
                            this.isSubscribed = false;
                        }, 3000);


                        /* window.scrollTo({
                             top: 0,
                             behavior: "smooth"
                         });*/
                    }
                })
                .catch((error) => {
                    console.warn('API request error: ', error);
                    this.error = error;
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },

    },
    mounted() {
        this.isSubscribed = false;
    }


};
</script>

<style lang="scss">

.v-dialog {
    background-color: $colorWhite;
    //width: 300px;
    @include md {
        width: 650px;
    }
    @include lg {
        width: 900px;
    }
}


.popup {

    &__title-span {
        word-break: break-word;
        text-align: center;
    }

    &.btn {
        &:hover {
            background: $colorRed;
            color: $colorWhite;
            border: 2px solid $colorRed;
        }
    }

}
</style>