<template>
    <v-app class="main-app">
        <the-header/>
        <v-main>
            <transition class="fade-transition">
                <router-view/>
            </transition>
        </v-main>
        <the-footer/>
    </v-app>
</template>
<script>
import TheHeader from "../components/the-header";
import Home from "@/views/home.vue";
import TheFooter from "@/components/the-footer";


export default {
    name: "LayoutMain",
    components: {
        TheFooter,
        TheHeader,
        Home
    },

};
</script>
<style lang="scss">
.main-app {
    .v-main {
        padding-top: 56px;
        @include lg {
            padding-top: 0;
        }
    }
    & footer {
        margin: 50px 0;
        @include md {
            margin: 0  0 100px 0;
        }
        @include lg {
            margin: 0  0 130px 0;
        }
    }
}
</style>
