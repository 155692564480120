export default [
    {
        name: "Kia",
        img: "/media/auto-new/kia.png",
        discount: 'Выгода до 970 000 ₽',
        oldPrice: '3 000 000 руб.',
        newPrice: '2 500 000 руб.',
        link: 'https://alarm-motors.ru/novye-avtomobili/kia?utm_medium=parking_bot&utm_source=telegram'
    },
    {
        name: "Haval",
        img: "/media/auto-new/haval.png",
        discount: 'Выгода до 600 000 ₽',
        oldPrice: '3 000 000 руб.',
        newPrice: '2 500 000 руб.',
        link: 'https://alarm-motors.ru/novye-avtomobili/haval?utm_medium=parking_bot&utm_source=telegram'
    },
    {
        name: "Geely",
        img: "/media/auto-new/geely.png",
        discount: 'Выгода до 750 000 ₽',
        oldPrice: '3 000 000 руб.',
        newPrice: '2 500 000 руб.',
        link: 'https://alarm-motors.ru/novye-avtomobili/geely?utm_medium=parking_bot&utm_source=telegram'
    },
    {
        name: "Hyundai",
        img: "/media/auto-new/hyundai.png",
        discount: 'Выгода до 650 000 ₽',
        oldPrice: '3 000 000 руб.',
        newPrice: '2 500 000 руб.',
        link: 'https://alarm-motors.ru/novye-avtomobili/hyundai?utm_medium=parking_bot&utm_source=telegram'
    },
    {
        name: "Omoda",
        img: "/media/auto-new/omoda.png",
        discount: 'Выгода до 495 000 ₽',
        oldPrice: '3 000 000 руб.',
        newPrice: '2 500 000 руб.',
        link: 'https://alarm-motors.ru/novye-avtomobili/omoda?utm_medium=parking_bot&utm_source=telegram'
    },
]
