<template>
    <section class="dealer-centers white" v-if="!currentDealer">
        <header class="dealer-centers__header px-12 py-10">
            <h2 class="dealer-centers__title text-left mb-6">Дилерские центры</h2>
            <p class="dealer-centers__subtitle mb-0">
                Мы всегда рады видеть вас в наших <br> дилерских центрах.
            </p>
        </header>
        <ul class="reset--list-style dealer-centers__list px-12 py-10">
            <li
                v-for="item in dealers"
                :key="item.title"
                class="dealer-centers__item"
            >
                <a
                    @mouseover="onMouseover(item)"
                    @mouseleave="onMouseleave(item)"
                    :href="`${item.address}`"
                    class="dealer-centers__item-link"
                    :class="{
            'dealer-centers__item-link-hover':
              item.title === (hoverItem && hoverItem.title),
          }"
                    @click.prevent="showDetails(item)"
                >
                    <div class="d-flex justify-space-between">
                        <div class="mb-2">
                            <h5 class="text-h5 dealer-centers__item-title">
                                {{ item.name }}
                            </h5>
                            <p class="dealer-centers__item-address mb-0 size-5">
                                {{ item.address }}
                            </p>
                            <p class="dealer-centers__item-address mb-0 inn-text info--text">
                                {{ item.nameINN }}
                            </p>
                            <p class="dealer-centers__item-address mb-0 inn-text info--text">
                                {{ item.INN }}
                            </p>
                        </div>
                        <div>
                            <v-btn icon large >
                                <svg class="dealer-centers__item-icon" width="36" height="36" viewBox="0 0 36 36"
                                     fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="18" cy="18" r="18" fill="#F2F3F7"/>
                                    <path d="M16 22L20 18L16 14" stroke="#848AA2" stroke-width="2"/>
                                </svg>
                            </v-btn>
                        </div>
                    </div>
                    <div class="d-flex justify-space-between size-5">
                        <a :href="`tel:${item.phone}`" class="dealer-centers__item-phone">
                            {{ item.phone | phone }}
                        </a>
                        <p class="dealer-centers__item-work-time mb-0 info--text">
                            {{ item.workTime }}
                        </p>
                    </div>
                </a>
            </li>
        </ul>
    </section>
    <DealerDetail
        v-else
        :item="currentDealer"
        @show-dealers="currentDealer = null"
    />
</template>
<script>
import DealerDetail from "./DealerCentersDetail";

export default {
    name: "DealerCenters",

    components: {
        DealerDetail,
    },

    props: {
        dealers: {
            type: Array,
            required: true,
        },
    },

    watch: {
        currentDealer(val) {
            if (!val) this.$emit("show-all");
        },
    },

    data: () => ({
        currentDealer: null,
        hoverItem: null,
    }),

    methods: {
        showDetails(item) {
            this.currentDealer = item;
            this.$emit("zoom-map", item);
        },
        onMouseover(item) {
            this.hoverItem = item;
            this.$emit("mouseover", item);
        },
        onMouseleave(item) {
            this.hoverItem = null;
            this.$emit("mouseoleave");
        },
    },
};
</script>

<style lang="scss">
.dealer-centers {
    max-width: 910px;
    margin: 30px 0 !important;
    @include lg {
        //max-width: 910px;
    }

    &__header {
        background-color: $colorGreyLight;
    }

    &__list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 20px;
    }


    &__item {
        padding: 35px 0;
        &:not(:nth-last-child(-n+2)) {
            border-bottom: 1px solid #ccc;
        }

        &-phone {
            // @include font("x-normal");
        }

        &-link-hover &-title {
            // color: $primary;
        }

        &-link:hover &-icon,
        &-link-hover &-icon,
        &-link:focus &-icon {
            circle {
                // fill: $primary;
            }

            path {
                // stroke: $white;
            }
        }
    }

    &-link {
    }
}

body .inn-text {
    font-size: 10px;
}
</style>
