<template>
    <section class="auto-old">
        <v-container>
            <h2>Выгоды на автомобили с пробегом</h2>
            <ul class="mb-8 mb-md-16 size-4 pl-5">
                <li>Более 400 автомобилей в наличии</li>
                <li>3 дилерских центра</li>
                <li>Гибкие условия продажи</li>
            </ul>
            <div class="auto-old__list text-center mb-8 mb-md-16">
                <v-row class="justify-center">
                    <v-col cols="6" md="3" v-for="(item, index) of AutoOld"
                           :key="index">
                        <a
                            :href="item.link"
                            class="auto-old__item">
                            <div class="auto-old__content">
                                <img :src="item.img" class="auto-old__img">
                            </div>
                            <div class="auto-old__text mt-4">
                                {{ item.name }}
                            </div>
                            <div class="auto-old__text">
                                {{ item.name2 }}
                            </div>
                        </a>
                    </v-col>
                </v-row>
            </div>

            <popup>
                <button class="btn" type="button">Получить персональное предложение</button>
            </popup>

        </v-container>
    </section>
</template>

<script>
import AutoOld from "../js/auto-old";
import Popup from "@/components/popup";

export default {
    name: 'AutoOld',
    components: {Popup},
    data() {
        return {
            AutoOld
        }
    },
}
</script>

<style lang="scss">
.auto-old {
    &__list {

    }

    & ul {
        list-style: disc !important;

        & li {
            &:not(:last-child) {
                margin-bottom: 15px;
            }
        }
    }

    &__item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &:hover {
            color: $colorRed !important;

            & .auto-old__content {

            }
        }
    }



    &__img {
        height: 120px;
        width: 120px;
    }
}
</style>