<template>
    <div class="dealer-centers-mobile-detail">
        <yandex-map
            class="dealer-centers-mobile-detail__map"
            :style="`height: 322px;`"
            :coords="item.coords"
            @map-was-initialized="initMap"
            :controls="[]"
            :zoom="10"
        >
            <ymap-marker
                :key="item.title"
                :marker-type="item.type || 'Placemark'"
                :coords="item.coords"
                :hint-content="item.address || 'Аларм-Моторс'"
                :icon="icon"
                :markerId="item.address"
            />
        </yandex-map>

        <!--<v-img height="160px" width="100%" :src="item.img" class="mb-3" />-->

        <div class="d-flex align-center mb-3 justify-center">
            <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle cx="15" cy="15" r="15" fill="#ED1C24"/>
                <mask
                    id="mask0"
                    mask-type="alpha"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="30"
                    height="30"
                >
                    <circle cx="15" cy="15" r="15" fill="#ED1C24"/>
                </mask>
                <g mask="url(#mask0)">
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M22 12L22 9C22 8.44772 21.5523 8 21 8L9 8C8.44772 8 8 8.44772 8 9L8 12L22 12ZM22 14L22 33C22 33.5523 21.5523 34 21 34L9 34C8.44772 34 8 33.5523 8 33L8 14L22 14ZM21 36C22.6569 36 24 34.6569 24 33L24 23L24.002 23C24.5542 23 25.002 22.5523 25.002 22L25.002 18C25.002 17.4477 24.5542 17 24.002 17L24 17L24 16L24.002 16C24.5542 16 25.002 15.5523 25.002 15L25.002 13C25.002 12.4477 24.5542 12 24.002 12L24 12L24 9C24 7.34315 22.6569 6 21 6L9 6C7.34315 6 6 7.34315 6 9L6 33C6 34.6569 7.34315 36 9 36L21 36ZM17.002 10C17.002 9.44772 16.5542 9 16.002 9L14.002 9C13.4497 9 13.002 9.44772 13.002 10C13.002 10.5523 13.4497 11 14.002 11L16.002 11C16.5542 11 17.002 10.5523 17.002 10ZM19.002 11C18.4497 11 18.002 10.5523 18.002 10C18.002 9.44772 18.4497 9 19.002 9C19.5542 9 20.002 9.44772 20.002 10C20.002 10.5523 19.5542 11 19.002 11Z"
                        fill="white"
                    />
                </g>
            </svg>

            <a :href="`tel:${item.phone}`" class="text-h6 ml-2 font-weight-bold">
                {{ item.phone | phone("+7 ($1) $2-$3-$4") }}
            </a>
        </div>

        <hr/>

        <ul class="reset--list-style dealer-centers-mobile-detail__work-time-list pb-2 pt-2">
            <li class="mb-5">
                <a :href="item.navLink" target="_blank">Перейти в Яндекс.Навигатор</a>
            </li>
            <li>
                {{ item.hours }}
            </li>
        </ul>

        <hr class="mb-3"/>

        <!--<UserSlider class="mb-3" :items="item.users" />-->

        <popup>
            <button class="btn">
                Получить персональное предложение
            </button>
        </popup>

    </div>
</template>
<script>
import Popup from "@/components/popup";
//import UserSlider from "./DealerCentersMobileDetailSlider.vue";

export default {
    name: "HomeBigMap",

    components: {
        Popup
        // UserSlider,
    },

    props: {
        item: {
            type: Object,
            required: true,
        },
    },

    data: () => ({
        map: null,
    }),

    watch: {
        map(ymap) {
            if (!ymap) return;
            ymap.behaviors.disable("scrollZoom");
            ymap.behaviors.disable("drag");
        },
    },

    computed: {
        icon() {
            return {
                layout: "default#imageWithContent",
                imageHref: YANDEX_MARKER_ICON_HOVER,
                imageSize: [50, 56],
                imageOffset: [0, 0],
            };
        },
    },

    methods: {
        initMap(map) {
            this.map = map;
        },
    },

    mounted() {
    },
};
</script>
<style lang="scss">
.dealer-centers-mobile-detail {
    // padding: 10px;

    &__map {
        margin: 0 auto 20px !important;
    }

    p {
        //@include font("x-small");
    }

    .base-button.v-btn {
        .v-btn__content {

            text-transform: none;
        }
    }

    hr {
        margin: 0;
        border: 0;
        height: 1px;
        //background: $info;
        opacity: 0.1;
    }
}
</style>
